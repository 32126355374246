import styled from '@emotion/styled';

import { Badge } from '@/components/common/Badge';
import { screen } from '@/components/common/breakpoints';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { HeadingLevelProvider } from '@/components/common/Heading/context';
import { Section } from '@/components/layout/Section';

import type { AlignmentOptions } from '@/components/common/CaptionHeadingDescription';
import type { SanityStandaloneHeadingType } from '@/types/sanity';

export interface StandaloneHeadingProps extends SanityStandaloneHeadingType {}

const HeadingLevelWrapper = ({ headingLevel, children }) =>
  headingLevel === 1 ? (
    children
  ) : (
    <HeadingLevelProvider level={headingLevel}>{children}</HeadingLevelProvider>
  );

export const StandaloneHeading = ({
  tag,
  heading,
  headingLevel,
  desktopAlignment = 'center',
  mobileAlignment = 'center',
  caption,
  subheading,
  isCaptionUppercase,
  paddingAdjustment = 'default',
  backgroundColour,
}: StandaloneHeadingProps) => {
  return (
    <Section
      backgroundColour={backgroundColour?.token}
      verticalPadding={paddingAdjustment}
      className="standalone-heading"
    >
      <HeadingLevelWrapper headingLevel={headingLevel}>
        <OuterWrapper
          desktopAlignment={desktopAlignment as AlignmentOptions}
          mobileAlignment={mobileAlignment as AlignmentOptions}
        >
          {tag?.tagText && (
            <Badge
              bgColour={tag?.tagColour}
              margin="0 0 var(--spacing-xxx-small) 0"
            >
              {tag.tagText}
            </Badge>
          )}
          <CaptionHeadingDescription
            desktopAlignment={desktopAlignment}
            mobileAlignment={mobileAlignment}
            caption={caption}
            heading={heading}
            description={subheading}
            isCaptionUppercase={isCaptionUppercase}
            className="standalone-chd"
          />
        </OuterWrapper>
      </HeadingLevelWrapper>
    </Section>
  );
};

const OuterWrapper = styled.div<{
  desktopAlignment: AlignmentOptions;
  mobileAlignment: AlignmentOptions;
}>`
  margin: 0 auto;
  text-align: ${({ mobileAlignment }) => `${mobileAlignment}`};

  ${screen.md} {
    text-align: ${({ desktopAlignment }) => `${desktopAlignment}`};
  }

  .standalone-chd {
    margin-bottom: 0;
  }
`;
