import styled from '@emotion/styled';

import { getTextColour } from '@/lib/colourUtils';
import { labelLarge } from '@/styles/typography';

import type {
  DecorativeColourTokenType,
  DecorativeColourType,
  TextColourTokenType,
} from '@/types/colours';
import type { ReactNode } from 'react';

type sizes = 'lg' | 'md' | 'sm';

export interface BadgeProps {
  bgColour?: DecorativeColourType;
  textColour?: TextColourTokenType;
  children: string | ReactNode;
  margin?: string;
  size?: sizes;
}

export const Badge = ({
  children,
  bgColour: { token: bgColourToken } = {
    title: 'eclipse / eclipse-200',
    value: 'rgb(221, 252, 157)',
    token: '--decorative-eclipse',
  },
  textColour,
  margin = '0 0 var(--spacing-small) 0',
  size = 'lg',
}: BadgeProps) => {
  return (
    <BadgeContainer
      bgColour={bgColourToken}
      textColour={textColour || getTextColour(bgColourToken)}
      margin={margin}
      size={size}
    >
      {children}
    </BadgeContainer>
  );
};

const BadgeContainer = styled.span<{
  bgColour: DecorativeColourTokenType;
  textColour: TextColourTokenType;
  margin: string;
  size: sizes;
}>`
  ${labelLarge}
  display: inline-block;
  width: fit-content;
  padding: var(--spacing-xxx-small) var(--spacing-xx-small);
  border-radius: var(--radius-100);
  background-color: ${(props) => `var(${props.bgColour})`};
  color: ${(props) =>
    (props.textColour && `var(${props.textColour})`) ||
    '--text-inverse-strong'};
  ${(props) => props.margin && `margin: ${props.margin}`};

  ${({ size }) => {
    switch (size) {
      case 'lg':
        return `
          padding: 7px 12px;
        `;
      case 'md':
        return `
          font-size: var(--font-size-2);
          padding: 7px 10px 6px;
        `;
      case 'sm':
        return `
          font-size: var(--font-size-1);
          padding: 6px 8px 5px;
        `;
      default:
        return 'padding: 7px 12px;';
    }
  }}
`;
